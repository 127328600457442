import { LoginDto, User, useLoginMutation } from "../types";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useValidation } from "vue3-form-validation";
import { computed, reactive, ref } from "vue";
import { setActiveActivity } from "@/graphql/session";
import { ActiveActivity } from "@/plugins/i18n";
import { validMail } from "@/graphql/utils/send-mail";
import { featureIcons } from "@/layouts/workspace/menu-settings";
import { CONSTANTS } from "@/graphql/utils/utils";
import { secureLocalStorage } from "../utils/encrypt-storage";
import { getFirstReadAccess } from "@/utils/activity";

export const useSingIn = () => {
  const toast = useToast();
  const { t } = useI18n();
  const { push } = useRouter();
  const dialog = reactive({
    show: false,
    activities: [],
  });
  const { validateFields, hasError, form } = useValidation({
    email: {
      $value: "",
      $rules: [(m: string) => !validMail(m) && t("signIn.validation.email")],
    },
    password: {
      $value: "",
      $rules: [(n: string) => !n && t("signIn.validation.password")],
    },
  });

  const savedLogin = secureLocalStorage.getItem(CONSTANTS.stayConnected);
  const input = reactive({
    email: savedLogin?.email || "",
    password: savedLogin?.password || "",
  });

  const stayConnected = ref<boolean>(!!savedLogin);
  const hidePassword = ref(true);
  const passwordType = computed(() =>
    input.password.trim() != "" && hidePassword.value ? "password" : "text"
  );

  const { mutate, loading, onDone, onError } = useLoginMutation({
    update: (cache, { data }) => {
      if (data?.login) {
        const { responsible, ...user } = data.login.user;
        secureLocalStorage.setItem(CONSTANTS.userType, user.isAdmin ? 1 : 0);
        cache.modify({
          fields: {
            whoAmI() {
              return user;
            },
            userResponsible(_, { toReference }) {
              return responsible.map((res) => toReference(res));
            },
          },
        });
      }
    },
  });

  function showDialog(user: User) {
    dialog.activities = user.responsible.map((resp) => ({
      label: resp.activity.name,
      style: `padding-top: 15px;font-weight: ${
        resp.roleId ? "normal" : "bold"
      }`,
      command: () => {
        const isOwner = resp.activity.ownerId === user.id;
        setActiveActivity({
          ...resp.activity,
          isOwner,
          access: resp.role?.access || [],
        });
        void push(
          `/workspace/${getFirstReadAccess(
            resp.role?.access,
            user.isAdmin || isOwner
          )}`
        );
      },
    }));
    if (user.isAdmin) {
      dialog.activities.push({
        label: t("workspaceLayout.administration"),
        icon: featureIcons.administration,
        command: () => void push("/admin"),
      });
    }
    dialog.show = true;
  }

  onDone(({ data, errors }) => {
    const login = data?.login as LoginDto;
    const user = login?.user;
    if (user) {
      switch (user.activation) {
        case 1:
          onConnected(login);
          switch (user.responsible.length) {
            case 0:
              setActiveActivity(new ActiveActivity());
              void push("/workspace/activity");
              break;
            case 1:
              if (!user.isAdmin) {
                const resp = user.responsible[0];
                const isOwner = resp.activity.ownerId === user.id;
                setActiveActivity({
                  ...resp.activity,
                  isOwner,
                  access: resp.role?.access || [],
                } as ActiveActivity);
                void push(
                  `/workspace/${getFirstReadAccess(
                    resp.role?.access,
                    user.isAdmin || isOwner
                  )}`
                );
              } else showDialog(user);
              break;
            default:
              showDialog(user);
              break;
          }
          break;
        default:
          sessionStorage.setItem(
            CONSTANTS.pendingAccount,
            JSON.stringify({
              email: user.email,
              name: user.name,
              id: user.id,
            })
          );
          toast.add({
            severity: "warn",
            summary: t("signIn.connexion"),
            detail: t("signIn.unverified"),
            life: parseInt(process.env.VUE_APP_TOAST_LIFE),
          });
          setTimeout(() => {
            void push("/account/verify-account");
          }, 2000);
          break;
      }
    } else {
      toast.add({
        severity: "warn",
        summary: t("signIn.connexion"),
        detail: t(`signIn.${errors[0].message}`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });

  onError((err) => {
    console.log(err);
    toast.add({
      severity: "warn",
      summary: t("signIn.connexion"),
      detail: t("networkError"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });

  function onConnected(login: LoginDto) {
    localStorage.setItem(CONSTANTS.token, login.token);
    if (stayConnected.value) {
      secureLocalStorage.setItem(CONSTANTS.stayConnected, input);
    } else secureLocalStorage.removeItem(CONSTANTS.stayConnected);
  }

  function submit() {
    Object.keys(input).forEach((key) => {
      form[key].$value = input[key];
    });
    validateFields().then((input) => {
      void mutate({ input });
    });
  }

  return {
    submit,
    input,
    hasError,
    form,
    loading,
    dialog,
    stayConnected,
    passwordType,
    hidePassword,
    accountBaseUrl: localStorage.getItem(CONSTANTS.appSource),
  };
};
