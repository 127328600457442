
import { defineComponent } from "vue";
import { useSingIn } from "@/graphql/user/sign-in";
import FieldErrors from "@/components/common/FieldErrors.vue";

export default defineComponent({
  name: "SignIn",
  components: { FieldErrors },
  setup() {
    return {
      ...useSingIn(),
    };
  },
});
